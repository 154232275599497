import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { KoreaLayout } from "@/components/layout"
import SEO from "../../components/seo"
import TabsComponent from "./tab"
import * as Styles from "./index.module.scss"
import { KoreaForm } from "../../components/form/KoreaForm"

/* ==================================================
  画像の読み込み
================================================== */
import arrow from "../../images/korea/message/arrow.svg"
import tamKoreaLogo from "../../images/korea/jumbotron/tamchang.png"

import messageImg01 from "../../images/korea/message/illust01.png"
import messageImg01w from "../../images/korea/message/illust01.webp"
import messageImg01Sp from "../../images/korea/message/illust01_sp.png"
import messageImg01Spw from "../../images/korea/message/illust01_sp.webp"

import companyHeading from "../../images/korea/company/company_kr.svg"
import companyHeadingSp from "../../images/korea/company/company_kr_sp.svg"
import companyImg from "../../images/korea/company/company.svg"
import companyImgSp from "../../images/korea/company/company_sp.svg"

import ServiceIcon01 from "../../images/korea/plan/icon_research.svg"
import ServiceIcon02 from "../../images/korea/plan/icon_strategy.svg"
import ServiceIcon03 from "../../images/korea/plan/icon_sns.svg"
import ServiceIcon04 from "../../images/korea/plan/icon_create.svg"
import ServiceIcon05 from "../../images/korea/plan/icon_influencer.svg"
import ServiceIcon06 from "../../images/korea/plan/icon_advertise.svg"

import resultCompany01 from "../../images/korea/result/company01.svg"
import resultCompany02 from "../../images/korea/result/company02.svg"
import resultCompany03 from "../../images/korea/result/company03.svg"
import resultCompany04 from "../../images/korea/result/company04.svg"
import resultCompany05 from "../../images/korea/result/company05.svg"
import resultCompany06 from "../../images/korea/result/company06.svg"
import resultCompany07 from "../../images/korea/result/company07.svg"
import resultCompany08 from "../../images/korea/result/company08.svg"
import resultCompany09 from "../../images/korea/result/company09.svg"
import resultCompany10 from "../../images/korea/result/company10.svg"
import resultCompany11 from "../../images/korea/result/company11.svg"
import resultCompany12 from "../../images/korea/result/company12.svg"
import resultCompany13 from "../../images/korea/result/company13.svg"
import resultCompany14 from "../../images/korea/result/company14.svg"
import resultCompany15 from "../../images/korea/result/company15.svg"
import resultCompany16 from "../../images/korea/result/company16.svg"
import resultCompany17 from "../../images/korea/result/company17.svg"
import resultCompany18 from "../../images/korea/result/company18.svg"
import resultCompany19 from "../../images/korea/result/company19.svg"
import resultCompany20 from "../../images/korea/result/company20.svg"
import resultCompany21 from "../../images/korea/result/company21.svg"
import resultCart01 from "../../images/korea/result/cart01.svg"
import resultCart02 from "../../images/korea/result/cart02.svg"
import resultCart03 from "../../images/korea/result/cart03.svg"
import resultCart04 from "../../images/korea/result/cart04.svg"
import resultCart05 from "../../images/korea/result/cart05.svg"
import resultCart06 from "../../images/korea/result/cart06.svg"
import resultCart07 from "../../images/korea/result/cart07.svg"
import resultCart08 from "../../images/korea/result/cart08.svg"
import resultCart09 from "../../images/korea/result/cart09.svg"

// companyロゴをオブジェクトに格納
const supportLogos = [
  { src: resultCompany01, alt: "KYOCERA Logo" },
  { src: resultCompany02, alt: "Panasonic Logo" },
  { src: resultCompany03, alt: "Nestle Logo" },
  { src: resultCompany04, alt: "FOSSIL Logo" },
  { src: resultCompany05, alt: "TOKYO METROPOLITAN GOVERNMENT Logo" },
  { src: resultCompany06, alt: "ANA Logo" },
  { src: resultCompany07, alt: "TATUNG Logo" },
  { src: resultCompany08, alt: "YANMAR Logo" },
]

const supportListItems = supportLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultSupportItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const ecLogos = [
  { src: resultCompany09, alt: "Belle Maison Logo" },
  { src: resultCompany10, alt: "Daimaru Matsuzakaya Department Stores Logo" },
  { src: resultCompany11, alt: "Kohnan Logo" },
  { src: resultCompany12, alt: "HIMARAYA Logo" },
  { src: resultCompany13, alt: "AOYAMA TAILOR Logo" },
  { src: resultCompany14, alt: "SUIT SQUARE Logo" },
  { src: resultCompany15, alt: "hare:kari AOYAMA Logo" },
  { src: resultCompany16, alt: "SHIMA SEIKI Logo" },
]

// ロゴとaltテキストを使用してリストアイテムを生成
const ecListItems = ecLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultEcItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

// companyロゴをオブジェクトに格納
const cartLogos = [
  { src: resultCart01, alt: "shopify Logo" },
  { src: resultCart02, alt: "ecforce Logo" },
  { src: resultCart03, alt: "ecbeing Logo" },
  { src: resultCart04, alt: "futureshop Logo" },
  { src: resultCart05, alt: "aishipR Logo" },
  { src: resultCart06, alt: "W2 Commerce Logo" },
  { src: resultCart07, alt: "ebisumart Logo" },
  { src: resultCart08, alt: "Lexica Logo" },
  { src: resultCart09, alt: "MakeShop Logo" },
]
const cartListItems = cartLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultCartItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const KoreaEnTopPage: React.VFC = () => {
  return (
    <>
      <SEO
        title="KANTAM | Marketing in Japan, cross-border e-commerce site development, and support for entering the Japanese market."
        lang="en"
        description="Specializing in Japan Market entry, Cross-Border E-Commerce, and Digital Marketing services, featuring web design and development Japanese language E-Commerce sites."
        image="https://oh-tam.com/korea/ogp_korea.jpg"
        url="https://oh-tam.com/korea_en"
        canonical="https://oh-tam.com/korea_en"
        keywords="Japanese Marketing, Cross-border E-commerce, Japanese Language Support EC Site Development, Cross-border E-commerce, E-commerce Site Development, Japanese Digital Marketing, Ecommerce, Web Design, Support for Entering Japan"
      />
      <KoreaLayout language="en">
        {/* FV */}
        <section className={Styles.topJumbotron} id={Styles.topJumbotron}>
          <div className={Styles.topJumbotronInner}>
            <div className={Styles.topJumbotronHeadingWrapper}>
              <h1 className={Styles.topJumbotronHeading}>
                <img
                  src={tamKoreaLogo}
                  alt="TAM Korea logo"
                  width="294"
                  height="64"
                />
              </h1>
              <div className={Styles.topJumbotronSubtitleWrapper}>
                <p className={Styles.topJumbotronSubtitle}>
                  Japanese Marketing,
                  <br className={Styles.spOnly} /> E-commerce
                </p>
              </div>
              <div className={Styles.topJumbotronTextWrapper}>
                <p className={Styles.topJumbotronText}>
                  We consistently support{" "}
                  <br className={Styles.spOnly} aria-hidden="true" />
                  cross-border{" "}
                  <br className={Styles.pcOnly} aria-hidden="true" />
                  e-commerce marketing targeting{" "}
                  <br className={Styles.pcOnly} aria-hidden="true" />
                  the Japanese market !
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Message */}
        <section className={Styles.topMessage} id={Styles.topMessage}>
          <div className={Styles.topMessageInner}>
            <div className={Styles.topMessageContainer}>
              <h2
                className={`${Styles.topMessageHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.en}>Message</span>
              </h2>
              <p className={Styles.topMessageSubtitle}>
                Hello!
                <br className={Styles.spOnly} />
                To Korean companies aiming to enter the Japanese market.
              </p>
              <p className={Styles.topMessageText}>
                We provide full support for cross-border e-commerce site
                establishment, promotion, and brand awareness in Japan.
                <br />
                We have a strong track record in supporting digital marketing,
                e-commerce strategies, deployment, and promotions for large
                corporations and famous brands in Japan.
                <br />
                Japanese and Korean staff who are familiar with the Japanese
                market work together to propose and implement the optimal plan
                that meets the needs of Japanese consumers and businesses.
                <br />
                Let’s make your business soar in the Japanese market!
              </p>
              <div className={Styles.topMessageLinkWrapper}>
                <a
                  href="https://www.tam-tam.co.jp/"
                  className={Styles.topMessageLink}
                  target="_blank"
                >
                  Click here to learn more about TAM group.
                  <img
                    src={arrow}
                    alt="arrow"
                    width="11"
                    height="11"
                    className={Styles.topMessageLinkArrow}
                  />
                </a>
              </div>
            </div>
            <div className={Styles.topMessageImageWrapper}>
              <picture>
                <source media="(max-width: 767px)" srcSet={messageImg01Spw} />
                <source media="(max-width: 767px)" srcSet={messageImg01Sp} />
                <source srcSet={messageImg01w} />
                <img
                  src={messageImg01}
                  alt="TAM member"
                  width="691"
                  height="537"
                  className={Styles.topMessageImage}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* Market */}
        <section className={Styles.topMarket} id={Styles.topMarket}>
          <div className={Styles.topMarketInner}>
            <div className={Styles.topMarketHeadingWrapper}>
              <h2
                className={`${Styles.topMarketHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>About Japan</span>
                <span className={Styles.en}>Market</span>
              </h2>
              <p className={Styles.topMarketSubtitle}>
                There are many opportunities
                <br aria-hidden="true" /> in cross-border e-commerce marketing
                targeting Japan !
              </p>
            </div>
            <ul className={Styles.topMarketList}>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    01
                    <span>To Customer</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    Korean popularity and product demand in the Japanese market
                    are increasing.
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        Among tourists visiting Korea, Japanese account for the
                        largest number.
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <StaticImage
                          placeholder="none"
                          alt="Foreign Tourist Statistics"
                          src="../../images/korea/market/graph01_en.png"
                          width={335}
                          height={152}
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          Source: Korea Tourism Organization{" "}
                          <i>April 2023 Foreign Tourist Statistics</i>
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        Japan ranks first in cosmetics imports by country.
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          7750
                          <span>billion yen</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          Source: Japan Imported Cosmetics Association{" "}
                          <i>2022 Import Amount by Country</i>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    02
                    <span>To Business</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    Japanese companies are active in entering the Korean market.
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        Ranked first in operating profit for Japanese companies
                        expanding overseas for two consecutive years.
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <StaticImage
                          placeholder="none"
                          alt="Japanese companies expanding overseas"
                          src="../../images/korea/market/graph02_en.png"
                          width={287}
                          height={151}
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          Source: JETRO{" "}
                          <i>
                            2022 Survey on the Status of Japanese Companies
                            Expanding Overseas | Korea version
                          </i>
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        More than 70% of Nikkei companies plan to expand their
                        sales functions in Korea.
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          74<span className={Styles.dot}>.</span>4
                          <span className={Styles.percent}>%</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          Source: JETRO{" "}
                          <i>
                            2022 Survey on the Status of Japanese Companies
                            Expanding Overseas | Korea version
                          </i>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* Company */}
        <section className={Styles.topCompany} id="topCompany">
          <div className={Styles.topCompanyInner}>
            <h2
              className={`${Styles.topCompanyHeading} ${Styles.topHeadingLv2}`}
            >
              <picture>
                <source media="(max-width: 767px)" srcSet={companyHeadingSp} />
                <img
                  src={companyHeading}
                  alt="탐그룹"
                  width="464"
                  height="181"
                  className={Styles.topCompanyHeadingImage}
                />
              </picture>
              <span className={Styles.en}>
                <span className={Styles.isSmall}>Global</span>Company
              </span>
            </h2>
            <div className={Styles.topCompanyImageWrapper}>
              <picture className={Styles.topCompanyPic}>
                <source media="(max-width: 767px)" srcSet={companyImgSp} />
                <img
                  src={companyImg}
                  alt="A map showing the locations of the company's offices scattered across the world. Marks are placed in Tokyo and Osaka as central points, with additional offices in Amsterdam, London, Taiwan, Singapore, San Francisco, and Hawaii."
                  width="938"
                  height="494"
                  className={Styles.topCompanyImage}
                />
              </picture>
            </div>
          </div>
        </section>

        <section className={Styles.topPlan} id="topPlan">
          <TabsComponent />
          <div className={Styles.topService} id="topService">
            <div className={Styles.topServiceInner}>
              <h2
                className={`${Styles.topServiceHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>Details</span>
                <span className={Styles.en}>
                  Service
                  <br className={Styles.spOnly} /> Menu
                </span>
              </h2>
              <p className={Styles.topServiceSubtitle}>
                You can customize your plan <br className={Styles.spOnly} />
                based on your needs <br className={Styles.spOnly} />
                and business situation !
              </p>
              <ul className={Styles.topServiceList}>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemResearch}`}
                >
                  <img
                    src={ServiceIcon01}
                    alt="research"
                    width="78"
                    height="78"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    Japanese market research
                  </p>
                  <p className={Styles.topServiceItemText}>
                    We conduct research through the Internet and user listening,
                    and <span>inexpensively investigate</span> the potential of
                    the Japanese market and user behavior and preferences.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemStrategy}`}
                >
                  <img
                    src={ServiceIcon02}
                    alt="strategy"
                    width="60"
                    height="73"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    Brand Building and Strategy
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>
                      We devise and implement marketing / creative plans
                    </span>{" "}
                    tailored to the interests of local users.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemSns}`}
                >
                  <img
                    src={ServiceIcon03}
                    alt="sns"
                    width="70"
                    height="79"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>SNS</p>
                  <p className={Styles.topServiceItemText}>
                    <span>
                      We support everything from strategy establishment to
                      production and operation
                    </span>{" "}
                    by utilizing social media tailored to your target, such as
                    Instagram, X, and TikTok.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemCreate}`}
                >
                  <img
                    src={ServiceIcon04}
                    alt="create"
                    width="71"
                    height="71"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    Creative production <br />
                    and site building
                  </p>
                  <p className={Styles.topServiceItemText}>
                    We carry out{" "}
                    <span>production based on marketing strategies.</span> Our
                    strength is that we can do everything from planning to
                    design and writing consistently.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemInfluencer}`}
                >
                  <img
                    src={ServiceIcon05}
                    alt="influencer"
                    width="81"
                    height="58"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    Influencer Planning
                  </p>
                  <p className={Styles.topServiceItemText}>
                    You can entrust us with{" "}
                    <span>
                      researching, planning, contacting appropriate influencers
                      , and even measuring the effects after implementation.
                    </span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemAdvertise}`}
                >
                  <img
                    src={ServiceIcon06}
                    alt="advertise"
                    width="70"
                    height="60"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    Digital advertising design <br />
                    and operation
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>
                      We produce advertising content and operate advertisements
                    </span>{" "}
                    in accordance with the characteristics of each advertising
                    platform in Japan and the behavior of target users.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={Styles.topResult} id="topResult">
          <div className={Styles.topResultInner}>
            <div className={Styles.topResultBgWhite}>
              <div className={Styles.topResultBgWhiteContainer}>
                <h2
                  className={`${Styles.topResultHeading} ${Styles.topHeadingLv2}`}
                >
                  <span className={Styles.ko}>Performance</span>
                  <span className={Styles.en}>
                    <span className={Styles.isSmall}>Support</span>
                    <span>Achievements</span>
                  </span>
                </h2>
                <div className={Styles.topResultSupportContainer}>
                  <div className={Styles.topResultSupportTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      TAM support performance
                    </h3>
                    <p className={Styles.topResultSupportText}>
                      Introducing some of the companies that TAM Group has
                      supported with marketing as partners.
                    </p>
                  </div>
                  <ul className={Styles.topResultSupportList}>
                    {supportListItems}
                  </ul>
                </div>
                <div className={Styles.topResultEcContainer}>
                  <div className={Styles.topResultEcTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      E-commerce support performance
                    </h3>
                    <p className={Styles.topResultEcText}>
                      We introduce some of the companies that E-commerce has
                      supported so far as partners.
                    </p>
                  </div>
                  <div className={Styles.topResultEcListContainer}>
                    <ul className={Styles.topResultEcList}>{ecListItems}</ul>
                    <ul className={Styles.topResultEcList}>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany17}
                          alt={"INE Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany18}
                          alt={"Heart Plus Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany19}
                          alt={"MEDICUS SHUPPAN,Publishers Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                    <ul className={Styles.topResultEcList}>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.topResultBgBeige}>
              <div className={Styles.topResultBgBeigeContainer}>
                <div className={Styles.topResultCartContainer}>
                  <div className={Styles.topResultCartTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4} ${Styles.topResultCartHeading}`}
                    >
                      Supports many <br className={Styles.spOnly} />
                      E-commerce platforms!
                    </h3>
                    <p className={Styles.topResultCartText}>
                      In addition to shopify, which is strong in cross-border
                      e-commerce, we can also support many platforms recommended
                      by Japanese commercial culture and marketing.
                      <br />
                      We will also provide advice on which platform to use
                      depending on the presence or absence of your business in
                      Japan and the characteristics of your products.
                    </p>
                  </div>
                  <ul className={Styles.topResultCartList}>{cartListItems}</ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <KoreaForm language="en" />
      </KoreaLayout>
    </>
  )
}

export default KoreaEnTopPage
