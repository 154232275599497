import React, { useState, useCallback, useEffect } from "react"
import * as Styles from "./index.module.scss"
import backgroundImage01 from "../../images/korea/plan/background01.jpg"
import backgroundImage01Sp from "../../images/korea/plan/background01_sp.jpg"
import backgroundImage02 from "../../images/korea/plan/background02.jpg"
import backgroundImage02Sp from "../../images/korea/plan/background02_sp.jpg"

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const TabsComponent = () => {
  //タブの状態をとる
  const [state, setState] = useState({
    tab: "panel1",
  })
  
  const windowSize = useWindowSize()

  //backgroundImageUrlsの型を宣言
  type BackgroundImageUrlsType = {
    [key: string]: string
  }

  // 画面幅に基づいて適切な背景画像を選択
  const backgroundImageUrls: BackgroundImageUrlsType = {
    panel1:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage01Sp
        : backgroundImage01,
    panel2:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage02Sp
        : backgroundImage02,
  }

  // クリックしたときのイベントハンドラー
  const handleTabClick = useCallback(event => {
    // イベント発生源の要素を取得
    const element = event.currentTarget

    // aria-controls 属性の値を取得（タブの名前） ex)"panel1"
    const tabState = element.getAttribute("aria-controls")

    // プロパティーを更新
    setState({
      tab: tabState,
    })
  }, [])

  const backgroundImageUrl = backgroundImageUrls[state.tab]

  return (
    <div
      className={Styles.topPlanInner}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <div className={Styles.topPlanContent}>
        <h2 className={`${Styles.topPlanHeading} ${Styles.topHeadingLv2}`}>
          <span className={Styles.ko}>Schedule guide</span>
          <span className={Styles.en}>Plan</span>
        </h2>
        <div className={Styles.topPlanTabListWrapper}>
          <ul role="tablist" className={`${Styles.topPlanTabList} tab-nav`}>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel1"
                aria-selected={state.tab === "panel1"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>
                  Plan to support cross-border online shopping malls targeting
                  Japanese consumers
                </span>
              </button>
            </li>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel2"
                aria-selected={state.tab === "panel2"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>
                  Marketing support plan targeting the Japanese market
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div className={Styles.topPlanListWrapper}>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel1"
            aria-hidden={state.tab !== "panel1"}
          >
            <p className={Styles.topPlanItemText}>
              We provide comprehensive support for commerce strategy, site
              construction, and promotions!
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Market research
                    </p>
                  </div>
                  <p className={Styles.topPlanSubItemText}>
                    We select the right target for your product and research
                    trends and interests of Japanese consumers.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Online shopping mall/content production
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    Using our knowledge and experience in improving the UI/UX of
                    e-commerce in Japan, we produce sites and content at the
                    high-quality production level of Japan.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Promotion - SNS/Advertisement
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    We plan and operate SNS and advertisements on Japanese
                    platforms that fit the target.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Effect analysis and policy establishment
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    We are also responsible for reporting after execution and
                    planning next steps.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel2"
            aria-hidden={state.tab !== "panel2"}
          >
            <p className={Styles.topPlanItemText}>
              We plan and execute promotional strategies, content creation, and
              social media operations!
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Market research
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    Research consumer trends, interests, competitors, etc. in
                    your local market.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Content Creation
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    Members with extensive Japanese marketing and digital
                    experience create effective content with high-quality
                    Japanese production standards.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Promotion - SNS/Advertisement
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    We plan and operate SNS and advertisements on Japanese
                    platforms that fit the target.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      Effect analysis and policy establishment
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    Responsible for effect analysis, policy formulation,
                    post-implementation reports, and devising next steps.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabsComponent
